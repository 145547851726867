<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.kostentraeger.menu"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="entities.kostentraeger.list.title"></app-i18n>
      </h1>

      <app-kostentraeger-list-toolbar></app-kostentraeger-list-toolbar>
      <app-kostentraeger-list-filter></app-kostentraeger-list-filter>
      <app-kostentraeger-list-table></app-kostentraeger-list-table>
    </div>
  </div>
</template>

<script>
import KostentraegerListFilter from '@/modules/kostentraeger/components/kostentraeger-list-filter.vue';
import KostentraegerListTable from '@/modules/kostentraeger/components/kostentraeger-list-table.vue';
import KostentraegerListToolbar from '@/modules/kostentraeger/components/kostentraeger-list-toolbar.vue';

export default {
  name: 'app-kostentraeger-list-page',

  components: {
    [KostentraegerListFilter.name]: KostentraegerListFilter,
    [KostentraegerListTable.name]: KostentraegerListTable,
    [KostentraegerListToolbar.name]: KostentraegerListToolbar,
  },
};
</script>

<style>
</style>
